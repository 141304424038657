import { Injectable } from '@angular/core';
import { BehaviorSubject , Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Subscriber } from './../models/subscriber'

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  public subscriberSubject: BehaviorSubject<Subscriber>;
  public subscriber$: Observable<Subscriber>;
  public isloggedin: boolean;
  private currentPage : BehaviorSubject<string>;
  public currentP$: Observable<string>;

  constructor(private router: Router,private http: HttpClient ) {
      this.subscriberSubject = new BehaviorSubject<Subscriber>(JSON.parse(localStorage.getItem('user')));

      this.subscriber$ = this.subscriberSubject.asObservable();

      this.currentPage = new BehaviorSubject<string>(sessionStorage.getItem('currentPage'));
      this.currentP$ = this.currentPage.asObservable();
   }

   public get userValue(): Subscriber {
    //console.log(this.subscriberSubject.value);
    return this.subscriberSubject.value;
  }

  public setSubject(data){
    console.log("Data update log storage",data)
    this.subscriberSubject.next(data)
  }

  // get state$():Observable<Subscriber>{
  //   return this.subscriberSubject.asObservable();
  // }


   public currentPageValue(str){
      this.currentPage.next(str);
      sessionStorage.setItem('currentPage', str);
   }

   public get getcurrentPage(){
    return this.currentPage.value;
   }

   public isUserLoggedIn(){
     return this.isloggedin;
   }

  registerProspect(subscriber: Subscriber) {
    /*const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      })
    };*/

    return this.http.post(`${environment.apiUrl}/prospect`, subscriber );
  }// end register

  sendOtp(otpDetail: any)
  {
    return this.http.post(`${environment.apiUrl}/send-otp`, otpDetail);
  }//end sendOtp

  forgetPass(data: any)
  {
    return this.http.post(`${environment.apiUrl}/forgetpassword`, data);
  }//end forgetPass
  
  paymentProcess(subscriberid){
    return this.http.post(`${environment.apiUrl}/paymentProcess`,{"prospectId" : subscriberid });
  }

  login(username, password, ipAddress,longitude,latitude,browser) {
    return this.http.post<Subscriber>(`${environment.apiUrl}/login`, { username, password ,ipAddress,longitude,latitude,browser})
        .pipe(map((user: any) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (!user.access_token) {
              throw new Error(user.message);
            }
            localStorage.setItem('user', JSON.stringify(user));
            this.subscriberSubject.next(user);
            this.isloggedin = true;
            return user;
        }));
  }// end login

  logout() {
    // remove user from local storage and set current user to null
    return this.http.post(`${environment.apiUrl}/logout`, {}).
      subscribe(
        data => {
          localStorage.removeItem('user');
          this.subscriberSubject.next(null);
          this.isloggedin = false;
          let branchData = { 'branch': "", 'showAllBranches': true };
          localStorage.setItem('userBranch', JSON.stringify(branchData));
          localStorage.setItem('showBranchPopup', "N");
          this.router.navigate(['/signin']);
        });
  }//end logout

  isEmailRegisterd(email){
    //console.log(this.http.post(`${environment.apiUrl}/checkEmail`,{"email" : email }).pipe(map((response: Response) => response.json())));
    return this.http.post(`${environment.apiUrl}/checkEmail`,{"email" : email }).pipe(map((response: any) => {
      if(response.statusCode == 400)  return response.json();
      else                            return null;
    }));
  }//end isEmailRegisterd

  isPhoneRegisterd(phone){
    //console.log(this.http.post(`${environment.apiUrl}/checkEmail`,{"email" : email }).pipe(map((response: Response) => response.json())));
    return this.http.post(`${environment.apiUrl}/checkPhone`,{"phone" : phone }).pipe(map((response: any) => {
      if(response.statusCode == 400)  return response.json();
      else                            return null;
    }));
  }//end isEmailRegisterd

  isGstRegisterd(gst){
    //console.log(this.http.post(`${environment.apiUrl}/checkEmail`,{"email" : email }).pipe(map((response: Response) => response.json())));
    return this.http.post(`${environment.apiUrl}/checkGst`,{"gst" : gst }).pipe(map((response: any) => {
      if(response.statusCode == 400)  return response.json();
      else                            return null;
    }));
  }//end isEmailRegisterd

  isUserNameCheck(userName){
    return this.http.post(`${environment.apiUrl}/checkUserName`,{"username" : userName }).pipe(map((response: any) => {
      if(response.statusCode == 400)  return response.json();
      else                            {  localStorage.setItem('user', JSON.stringify(response));  return null; }
    }));
  }//end isUserNameCheck

  sendPasswordOtp(otpDetail: any)
  {
    return this.http.post(`${environment.apiUrl}/sendPasswordOtp`, otpDetail);
  }//end sendOtp

/*OtpValid(otp , detail){
    return this.http.post(`${environment.apiUrl}/isValidOtp`,{"otp" : otp , detail }).pipe(map((response: any) => { 
      if(response.statusCode == 400)  return response.json();
      else                            return null; 
    }));
  }*///end isOtpValid

  OtpValid(otpData){
    return this.http.post(`${environment.apiUrl}/isValidOtp`,otpData);
  }//end isOtpValid

  resetPassword(pwdData){
    return this.http.post(`${environment.apiUrl}/resetPassword`, pwdData );
  }// end resetPassword


  checkAccess(pageName , mode){
    return this.http.post(`${environment.apiUrl}/checkAccess`,{"pageName" : pageName , "mode" : mode });
  }//end isOtpValid

  checkListAccess(pageName ){
    return this.http.post(`${environment.apiUrl}/checkListAccess`,{"pageName" : pageName });
  }//end isOtpValid

  checkAccessList(pageNames ){
    return this.http.post(`${environment.apiUrl}/checkAccessList`,{"pageNames" : pageNames });
  }//end isOtpValid


  /*getAll() {
    return this.http.get<{data :Subscriber[]}>(`${environment.apiUrl}/packages`);
  }// end getall
*/


}// end class
