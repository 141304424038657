import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { State } from './../models/state';
import { City } from './../models/city';
import { SubscriberService } from '@app/services/subscriber.service';
import { TrfdNotificationService } from '@app/services/trfd-notification.service';
import { Subscriber } from './../models/subscriber';
import { AutoLogoutService } from '@app/services/auto-logout.service';
import { first } from 'rxjs/operators';
import { Location, LocationStrategy } from '@angular/common';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  user: any = JSON.parse(localStorage.getItem('user'));
  public userDetail: Subscriber;
  public BiltyLabel: string = 'Bilty';
  public userBranch: any = JSON.parse(localStorage.getItem('userBranch'));
  public userBranchName: string = "";
  public userFinYear: string = "";
  public maxAddItemLimit: number = 0;
  public notificationCount: number = 0;
  public noSpaceRegex: any = new RegExp(/^[\w]+([-_\s]{1}[a-z0-9A-Z]+)*$/i);

  public previousUrl:string = ''
  public currentUrl:string = ''

  public dashboard: boolean = true;
  public biltyList: boolean = false;
  public crossingInwardList: boolean = false;
  public crossingOutwardList: boolean = false;
  public branchList: boolean = false;
  public designationList: boolean = false;
  public userList: boolean = false;
  public goodsList: boolean = false;
  public goodsCategory: boolean = false;
  public clientList: boolean = false;
  public vendorList: boolean = false;
  public distanceweight: boolean = false;
  public quotation: boolean = false;
  public routePlanner: boolean = false;
  public stationMaster: boolean = false;
  public packagingType: boolean = false;
  public contentType: boolean = false;
  public taxList: boolean = false;
  public contacts: boolean = false;
  public employeeList: boolean = false;
  public employeeTypeList: boolean = false;
  public loadingList: boolean = false;
  public challanList: boolean = false;
  public crossingChallanList: boolean = false;
  public inwardChallanList: boolean = false;
  public vehicleTypeList: boolean = false;
  public vehicleManufacturerList: boolean = false;
  public vehicleList: boolean = false;
  public drList: boolean = false;
  public deliveryChallanList: boolean = false;
  public podList: boolean = false;
  public tbbInvoiceList: boolean = false;
  public crossingOutwardInvoiceList: boolean = false;
  public orderList: boolean = false;
  public accountingGroupList: boolean = false;
  public accountingSubGroupList: boolean = false;
  public accountList = false;
  public bankReconciliationReportList = false;
  public generalEntryList = false;
  public paymentVoucherList = false;
  public receiptVoucherList = false;
  public voucherList = false;
  public bankVoucherList = false;
  public contraEntryList = false;
  public creditNoteList = false;
  public debitNoteList = false;
  public reports: boolean = false;
  public ledgers: boolean = false;
  public categoryList: boolean = false;
  public autopartList: boolean = false;
  public autopartType: boolean = false;
  public tripExpenses: boolean = false;
  public purchaseBill: boolean = false;
  public openingBal: boolean = false;
  public inventory: boolean = false;
  public issueAndReturn: boolean = false;
  public loginLogs: boolean = false;
  public paymentReceipts: boolean = false;
  public bookingRegReport: boolean = false;
  public collectionReport: boolean = false;
  public challanReport: boolean = false;
  public ddCartageReport: boolean = false;
  public pendingReport: boolean = false;
  public payments: boolean = false;
  public purchaseReturn: boolean = false;
  public sales: boolean = false;

  /* menu show/hide */
  public dashboardVisible: boolean = true;
  public orderVisible: boolean = false;
  public biltyVisible: boolean = false;
  public crossingInwardVisible: boolean = false;
  public crossingOutwardVisible: boolean = false;
  public loadingVisible: boolean = false;
  public challanVisible: boolean = false;
  public crossingChallanVisible: boolean = false;
  public inwardChallanVisible: boolean = false;
  public drVisible: boolean = false;
  public deliveryChallanVisible: boolean = false;
  public podVisible: boolean = false;
  public tbbInvoiceVisible: boolean = false;
  public crossingOutwardInvoiceVisible = false;
  public tripExpenseVisible: boolean = false;
  public purchaseBillVisible: boolean = false;

  public branchVisible: boolean = false;
  public designationVisible: boolean = false;
  public userVisible: boolean = false;
  public goodsVisible = false;
  public goodsCategoryVisible: boolean = false;
  public clientVisible: boolean = false;
  public vendorVisible: boolean = false;
  public distanceweightVisible: boolean = false;
  public quotationVisible: boolean = false;
  public routePlannerVisible:boolean = false;
  public stationMasterVisible: boolean = false;
  public packagingTypeVisible: boolean = false;
  public contentTypeVisible: boolean = false;
  public taxVisible: boolean = false;
  public contactsVisible: boolean = false;
  public employeeVisible: boolean = false;
  public employeeTypeVisible: boolean = false;
  public vehicleTypeVisible: boolean = false;
  public vehicleManufacturerVisible: boolean = false;
  public vehicleVisible: boolean = false;
  public accountingGroupVisible: boolean = false;
  public accountingSubGroupVisible: boolean = false;
  public accountVisible: boolean = false;
  public reportsVisible: boolean = false;
  public ledgerVisible: boolean = false;
  public categoryVisible: boolean = false;
  public autopartVisible: boolean = false;
  public autopartTypeVisible: boolean = false;
  public tripExpensesVisible: boolean = false;
  public openingBalVisible: boolean = false;
  public generalEntryVisible: boolean = false;
  public contraEntryVisible: boolean = false;
  public paymentVoucherVisible: boolean = false;
  public receiptVoucherVisible: boolean = false;
  public creditNoteVisible: boolean = false;
  public debitNoteVisible: boolean = false;
  public inventoryVisible: boolean = false;
  public issueAndReturnVisible: boolean = false;

  public workflowVisible: boolean = false;
  public masterVisible: boolean = false;
  public accountsVisible: boolean = false;
  public taxationVisible: boolean = false;
  public reportVisible: boolean = false;
  public loginLogVisible : boolean = false;
  public paymentReceiptsVisibe: boolean = false;

  public bookingRegReportVisible: boolean = false;
  public collectionReportVisible: boolean = false;
  public challanReportVisible: boolean = false;
  public pendingReportVisible: boolean = false;
  public accountBooksReportVisible:boolean = false;
  public bankBooksReportVisible:boolean = false;
  public balanceSheetReportVisible:boolean = false
  public bankReconciliationReportVisible:boolean = false
  public ddCartageReportVisible:boolean = false;
  public dayBookReportVisible:boolean = false;
  public journalReportVisible:boolean = false;
  public loginLogsReportVisible:boolean = false;
  public profitLossReportVisible:boolean = false;
  public motorFreightReportVisible: boolean = false;
  public pfReportVisible: boolean = false;
  public ddReportVisible: boolean = false;
  public trialBalanceReportVisible:boolean = false;
  public biltyDrStockReportVisible:boolean = false;
  public paymentsVisible: boolean = false;
  public purchaseReturnVisible: boolean = false;
  public salesVisible: boolean = false;
  public ibtApprovalVisible: boolean = false;

  /* Setting menu */
  public companyDetail: boolean = false;
  public dateTime: boolean = false;
  public workFlow: boolean = false;
  public localName: boolean = false;
  public documentNumber: boolean = false;
  public voucherNumber: boolean = false;
  public biltySettings: boolean = false;
  public biltyTabSettings: boolean = false;
  public biltyPrintSettings: boolean = false;
  public biltyIndexSettings: boolean = false;
  public settings: boolean = false;
  public employeeSetting: boolean = false;
  public vehicleSetting: boolean = false;
  public vendorSetting: boolean = false;
  public languageSetting: boolean = false;
  public notificationSetting: boolean = false;
  public loadingSetting: boolean = false;
  public crossingInwardSetting: boolean = false;
  public crossingOutwardSetting: boolean = false;
  public challanSetting: boolean = false;
  public drSetting: boolean = false;
  public paymentModeSetting: boolean = false;
  public orderSeting: boolean = false;
  public clientSetting: boolean = false;
  public fileImport: boolean = false;
  public fileExport: boolean = false;
  private currentUserBranch: BehaviorSubject<any> = new BehaviorSubject<any>(this.userBranch);

  /* transified menu */
  public transifiedFileImport = false;
  public trfdImport = false;
  public trfdDashboard = false;
  public trfdImportCancel = false;


  constructor(private http: HttpClient, private subscriberService: SubscriberService, private router:Router, private trfdNotificationService: TrfdNotificationService, private autologoutService: AutoLogoutService, private location: LocationStrategy) {
    if (this.user) {
      this.maxAddItemLimit = this.user.maxAddItemLimit;
      if (this.user.localName[0]) {
        this.BiltyLabel = (this.user.localName[0].localName ? this.user.localName[0].localName : 'Bilty');
      } else{
        this.BiltyLabel = 'Bilty';
      }
      // preventing back button in browser
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });

    }
    if (this.userBranch) {
      this.getUserBranchName();
    }
    this.userDetail = this.subscriberService.userValue;
    this.getuserPermissions();
    this.getNotificationCount();
    this.routeTracker()
  }

  getAllStates() {
    return this.http.get<{ data: State[] }>(`${environment.apiUrl}/states`);
  }

  getAllCities() {
    return this.http.get<{ data: City[] }>(`${environment.apiUrl}/cities`);
  }

  searchCity(keyword) {
    return this.http.post(`${environment.apiUrl}/citySearch`, {'keyword':keyword});
  }

  getAllCitiesByState(stateid) {
    return this.http.get<{ data: City[] }>(`${environment.apiUrl}/cities/${stateid}`);
  }

  // getRegex(){
  //   this.noSpaceRegex = new RegExp();
  // }

  getCount() {
    return this.http.get(`${environment.apiUrl}/notification-count`);
  }

  getAllScript() {
    return this.http.get<{ data: any }>(`${environment.apiUrl}/getAllScript`);
  }

  getAllDashboardScript() {
    return this.http.get<{ data: any }>(`${environment.apiUrl}/getAllDashboardScript`);
  }

  getUserCities() {
    return this.http.get<{ data: City[] }>(`${environment.apiUrl}/getUserCities`);
  }

  addCity(cityData: any) {
    return this.http.post(`${environment.apiUrl}/city-add`, cityData);
  }

  getlocalName() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      if (this.user.localName[0]) {
        this.BiltyLabel = (this.user.localName[0].localName ? this.user.localName[0].localName : 'Bilty');
      }
    }
  }
  getuserDetail() {
    this.userDetail = JSON.parse(localStorage.getItem('user'));
    this.getuserPermissions();
  }
  getUserBranch() {
    this.userBranch = JSON.parse(localStorage.getItem('userBranch'));
    this.getUserBranchName();
  }

  getUserBranchName() {
    this.userBranchName = JSON.parse(localStorage.getItem('userBranch'));
    if (this.userBranchName) {
      if (this.userBranch.branch.branchName != undefined) {
        this.userBranchName = this.userBranch.branch.branchName;
        this.currentUserBranch.next(this.userBranch);
      } else
        this.userBranchName = "";
        
      if (this.userBranch?.finYear?.finYear != undefined) {
        this.userFinYear = this.userBranch?.finYear?.finYear;
        this.currentUserBranch.next(this.userBranch);
      } else
        this.userFinYear = "";
        
    }
  }

  public getCurrentBranchName(): Observable<any> {
    return this.currentUserBranch.asObservable();
  }

  public getDocPringSettings(docType) {
    const user = JSON.parse(localStorage.getItem('user'));
    return user.doc_print_settings.find(doc_print => doc_print.doc_type === docType);
  }

  getPermissionsList() {
    return this.http.post(`${environment.apiUrl}/getPermissionsList`, {});
  }

  getuserPermissions() {
    this.getPermissionsList()
    .pipe(first()/*, takeUntil(this.unsubscribe$)*/)
    .subscribe(
      (data: any) => {
          let result = data;  
          if(data.status === false) return false;
          let isPrivlleges = Number(result.isPrivlleges);
          let permissionsList = result.permissions;  
          if (isPrivlleges !== 1 && permissionsList !== undefined) { 
            permissionsList.forEach((element, index) => {

              if (element.scriptName === 'Dashboard' && element.isVisible == 1) {
                this.dashboardVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Order' && element.isVisible == 1) {
                this.orderVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Bilty' && element.isVisible == 1) {
                this.biltyVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'CrossingInward' && element.isVisible == 1) {
                this.crossingInwardVisible = true;
                this.workflowVisible = true;
                
              } else if (element.scriptName == 'CrossingOutward' && element.isVisible == 1) {
                this.crossingOutwardVisible = true;
                this.workflowVisible = true;
                
              } else if (element.scriptName == 'Loading' && element.isVisible == 1) {
                this.loadingVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Challan' && element.isVisible == 1) {
                this.challanVisible = true;
                this.workflowVisible = true;

              } else if ((element.scriptName == 'CrossingChallan' || element.scriptName == 'crossingchallan' )&& element.isVisible == 1) {
                this.crossingChallanVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Inwardchallan' && element.isVisible == 1) {
                this.inwardChallanVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'DR' && element.isVisible == 1) {
                this.drVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'DeliveryChallan' && element.isVisible == 1) {
                this.deliveryChallanVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'POD' && element.isVisible == 1) {
                this.podVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'TbbInvoice' && element.isVisible == 1) {
                this.tbbInvoiceVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'CrossingOutwarddInvoice' && element.isVisible == 1) {
                this.crossingOutwardInvoiceVisible = true;
                this.workflowVisible = true;

              } else if ((element.scriptName == 'TripExpense' || element.scriptName == 'tripexpense' ) && element.isVisible == 1) {
                this.tripExpenseVisible = true;
                this.workflowVisible = true;

              } else if ((element.scriptName == 'PurchaseBill' || element.scriptName == 'puchasebill') && element.isVisible == 1) {
                this.purchaseBillVisible = true;
                this.workflowVisible = true;

              }  else if (element.scriptName == 'Category' && element.isVisible == 1) {
                this.categoryVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Autopart' && element.isVisible == 1) {
                this.autopartVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'AutopartType' && element.isVisible == 1) {
                this.autopartTypeVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Inventory' && element.isVisible == 1) {
                this.inventoryVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'IssueAndReturn' && element.isVisible == 1) {
                this.issueAndReturnVisible = true;
                this.workflowVisible = true;

              } else if (element.scriptName == 'Branch' && element.isVisible == 1) {
                this.branchVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Designation' && element.isVisible == 1) {
                this.designationVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'User' && element.isVisible == 1) {
                this.userVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Goods' && element.isVisible == 1) {
                this.goodsVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'GoodsCategory' && element.isVisible == 1) {
                this.goodsCategoryVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Client' && element.isVisible == 1) {
                this.clientVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Vendor' && element.isVisible == 1) {
                this.vendorVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'DistanceWeight' && element.isVisible == 1) {
                this.distanceweightVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'quotation' && element.isVisible == 1) {
                this.quotationVisible = true;
                this.masterVisible = true;

              }else if (element.scriptName == 'routePlanner' && element.isVisible == 1) {
                this.routePlannerVisible = true;
                this.masterVisible = true;
              } else if(element.scriptName == 'stationMaster' && element.isVisible == 1) {
                this.stationMasterVisible = true;
                this.masterVisible = true;
              } else if (element.scriptName == 'PackagingType' && element.isVisible == 1) {
                this.packagingTypeVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'ContentType' && element.isVisible == 1) {
                this.contentTypeVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Tax' && element.isVisible == 1) {
                this.taxVisible = true;
                this.taxationVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'contacts' && element.isVisible == 1) {
                this.contactsVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Employee' && element.isVisible == 1) {
                this.employeeVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'EmployeeType' && element.isVisible == 1) {
                this.employeeTypeVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'VehicleType' && element.isVisible == 1) {
                this.vehicleTypeVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'VehicleManufacturer' && element.isVisible == 1) {
                this.vehicleManufacturerVisible = true;
                this.masterVisible = true;

              } else if (element.scriptName == 'Vehicle' && element.isVisible == 1) {
                this.vehicleVisible = true;
                this.masterVisible = true;

              } else if ((element.scriptName == 'AccountingGroup' || element.scriptName == 'accountgroup') && element.isVisible == 1) {
                this.accountingGroupVisible = true;
                this.accountsVisible = true;

              } else if ((element.scriptName == 'AccountingSubGroup' || element.scriptName == 'accountsubgroup') && element.isVisible == 1) {
                this.accountingSubGroupVisible = true;
                this.accountsVisible = true;

              } else if (element.scriptName == 'Account' && element.isVisible == 1) {
                this.accountVisible = true;
                this.accountsVisible = true;

              } else if ((element.scriptName == 'Reports' || element.scriptName == 'report') && element.isVisible == 1) {
                this.reportsVisible = true;
                this.reportVisible = true;

              } else if ((element.scriptName == 'Ledger' || element.scriptName === 'ledger') && element.isVisible == 1) {
                this.ledgerVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'trialBalance'  && element.isVisible == 1) {
                this.trialBalanceReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'drStock'  && element.isVisible == 1) {
                this.biltyDrStockReportVisible = true;
                this.reportVisible = true;
              } else if (element.scriptName === 'profitLoss'  && element.isVisible == 1) {
                this.profitLossReportVisible = true;
                this.reportVisible = true;

              } else if (element.scriptName === 'pf'  && element.isVisible == 1) {
                this.pfReportVisible = true;
                this.reportVisible = true;

              } else if (element.scriptName === 'motorFreight'  && element.isVisible == 1) {
                this.motorFreightReportVisible = true;
                this.reportVisible = true;

              } else if (element.scriptName === 'loginLogs' && element.isVisible == 1) {
                this.loginLogsReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'journal' && element.isVisible == 1) {
                this.journalReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'bookingRegister' && element.isVisible == 1) {
                this.bookingRegReportVisible = true;
                this.reportVisible = true;

              }else if (element.scriptName === 'collection' && element.isVisible == 1) {
                this.collectionReportVisible = true;
                this.reportVisible = true;

              }else if (element.scriptName === 'challanReport' && element.isVisible == 1) {
                this.challanReportVisible = true;
                this.reportVisible = true;

              }else if (element.scriptName === 'ddCartage' && element.isVisible == 1) {
                this.ddCartageReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'dayBook' && element.isVisible == 1) {
                this.dayBookReportVisible = true;
                this.reportVisible = true;

              }else if (element.scriptName === 'doorDelivery' && element.isVisible == 1) {
                this.ddReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'bankReconciliation' && element.isVisible == 1) {
                this.bankReconciliationReportVisible = true;
                this.accountsVisible = true;

              }
              else if (element.scriptName === 'cashBook' && element.isVisible == 1) {
                this.accountBooksReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'bankBook' && element.isVisible == 1) {
                this.bankBooksReportVisible = true;
                this.reportVisible = true;

              }
              else if (element.scriptName === 'balanceSheet' && element.isVisible == 1) {
                this.balanceSheetReportVisible = true;
                this.reportVisible = true;

              }
              else if ((element.scriptName == 'OpeningBalance' || element.scriptName == 'openingbalance') && element.isVisible == 1) {
                this.openingBalVisible = true
                this.accountsVisible = true;

              } else if ((element.scriptName == 'GeneralEntry' || element.scriptName == 'generalEntry') && element.isVisible == 1) {
                this.generalEntryVisible = true;
                this.accountsVisible = true;

              } else if ((element.scriptName == 'PaymentVoucherEntry' || element.scriptName == 'cashVoucherEntry') && element.isVisible == 1) {
                this.paymentVoucherVisible = true;
                this.accountsVisible = true;

              } else if ((element.scriptName == 'ReceiptVoucherEntry' || element.scriptName == 'cashVoucherEntry') && element.isVisible == 1) {
                this.receiptVoucherVisible = true;
                this.accountsVisible = true;

              }  else if ((element.scriptName == 'ContraEntry' || element.scriptName == 'contraEntry' ) && element.isVisible == 1) {
                this.contraEntryVisible = true;
                this.accountsVisible = true;

              } else if (element.scriptName == 'CreditNote' && element.isVisible == 1) {
                this.creditNoteVisible = true;
                this.accountsVisible = true;

              } else if (element.scriptName == 'DebitNote' && element.isVisible == 1) {
                this.debitNoteVisible = true;
                this.accountsVisible = true;

              } else if (element.scriptName == 'Receipts' && element.isVisible == 1) {
                this.paymentReceiptsVisibe = true;

              } else if ((element.scriptName == 'pendingdr' || element.scriptName == 'pendingReports') && element.isVisible == 1) {
                this.pendingReportVisible = true;
                this.reportVisible = true;

              } else if ((element.scriptName == 'Payments' || element.scriptName == 'payments') && element.isVisible == 1) {
                this.paymentsVisible = true;
                this.workflowVisible = true

              } else if (element.scriptName == 'PurchaseReturn' && element.isVisible == 1) {
                this.purchaseReturnVisible = true;

              } else if ((element.scriptName == 'Sales' || element.scriptName == 'SalesInvoice' ) && element.isVisible == 1) {
                this.salesVisible = true;
                this.workflowVisible = true

              } else if (element.scriptName == 'ibtApproval' && element.isVisible == 1) {
                this.ibtApprovalVisible = true;

              } else {

              }
            });
          } else { 
            this.workflowVisible = true;
            this.masterVisible = true;
            this.accountsVisible = true;
            this.taxationVisible = true;
            this.reportVisible = true

            this.dashboardVisible = true;
            this.categoryVisible = true;
            this.orderVisible = true;
            this.biltyVisible = true;
            this.crossingInwardVisible = true;
            this.crossingOutwardVisible = true;
            this.crossingChallanVisible = true;
            this.loadingVisible = true;
            this.challanVisible = true;
            this.crossingChallanVisible = true;
            this.inwardChallanVisible = true;
            this.drVisible = true;
            this.deliveryChallanVisible = true;
            this.podVisible = true;
            this.tbbInvoiceVisible = true;
            this.crossingOutwardInvoiceVisible = true;
            this.tripExpenseVisible = true;
            this.purchaseBillVisible = true;
            this.autopartVisible = true;

            this.branchVisible = true;
            this.designationVisible = true;
            this.userVisible = true;
            this.goodsVisible = true;
            this.goodsCategoryVisible = true;
            this.clientVisible = true;
            this.vendorVisible = true;
            this.distanceweightVisible = true;
            this.quotationVisible = true;
            this.routePlannerVisible = true;
            this.stationMasterVisible = true;
            this.autopartTypeVisible = true;
            this.packagingTypeVisible = true;
            this.contentTypeVisible = true;
            this.taxVisible = true;
            this.contactsVisible = true;
            this.employeeVisible = true;
            this.employeeTypeVisible = true;
            this.vehicleTypeVisible = true;
            this.vehicleManufacturerVisible = true;
            this.vehicleVisible = true;
            this.accountingGroupVisible = true;
            this.accountingSubGroupVisible = true;
            this.accountVisible = true;
            this.reportsVisible = true;
            this.openingBalVisible = true;
            this.generalEntryVisible = true;
            this.contraEntryVisible = true;
            this.paymentVoucherVisible = true;
            this.receiptVoucherVisible = true;
            this.creditNoteVisible = true;
            this.debitNoteVisible = true;
            this.inventoryVisible = true;
            this.issueAndReturnVisible = true;
            this.loginLogVisible = true;
            this.paymentReceiptsVisibe = true;

            this.bookingRegReportVisible = true;
            this.collectionReportVisible = true;
            this.challanReportVisible = true;
            this.ddCartageReportVisible = true;
            this.pendingReportVisible = true;
            this.accountBooksReportVisible = true
            this.bankBooksReportVisible = true
            this.balanceSheetReportVisible = true
            this.bankReconciliationReportVisible = true
            this.dayBookReportVisible = true
            this.ddReportVisible = true
            this.journalReportVisible = true
            this.loginLogsReportVisible = true
            this.profitLossReportVisible = true
            this.motorFreightReportVisible = true;
            this.pfReportVisible = true;
            this.trialBalanceReportVisible = true
            this.biltyDrStockReportVisible = true;
            this.ledgerVisible = true;

            this.paymentsVisible = true;
            this.purchaseReturnVisible = true;
            this.salesVisible = true;
            this.ibtApprovalVisible = true;
          }
      },
      error => {
         console.log("something went wrong");
      });
  }

  getNotificationCount() {
    this.trfdNotificationService.getCount().pipe(first()).subscribe((data: any) => {
      this.notificationCount = data.counts;
    });
  }

  routeTracker(){
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
      this.previousUrl = events[0].urlAfterRedirects
      this.currentUrl =  events[1].urlAfterRedirects
      return [this.previousUrl, this.currentUrl]
    });
  }

  activeMenu(page) {
    page = page.trim();
    page = page.toString();
    this.dashboard = false;
    this.biltyList = false;
    this.crossingInwardList = false;
    this.crossingOutwardList = false;
    this.branchList = false;
    this.designationList = false;
    this.userList = false;
    this.goodsCategory = false;
    this.goodsList = false;
    this.clientList = false;
    this.vendorList = false;
    this.distanceweight = false;
    this.quotation = false;
    this.routePlanner = false;
    this.stationMaster = false;
    this.packagingType = false;
    this.contentType = false;
    this.taxList = false;
    this.contacts = false;
    this.employeeList = false;
    this.employeeTypeList = false;
    this.vehicleTypeList = false;
    this.vehicleManufacturerList = false;
    this.vehicleList = false;
    this.loadingList = false;
    this.challanList = false;
    this.crossingChallanList = false;
    this.inwardChallanList = false;
    this.drList = false;
    this.deliveryChallanList = false;
    this.podList = false;
    this.tbbInvoiceList = false;
    this.crossingOutwardInvoiceList = false;
    this.orderList = false;
    this.accountingGroupList = false;
    this.accountingSubGroupList = false;
    this.accountList = false;
    this.bankReconciliationReportList = false;
    this.generalEntryList = false;
    this.paymentVoucherList = false;
    this.receiptVoucherList = false;
    this.voucherList = false;
    this.bankVoucherList = false;
    this.contraEntryList = false;
    this.creditNoteList = false;
    this.debitNoteList = false;
    this.reports = false;
    this.ledgers = false;
    this.categoryList = false;
    this.autopartList = false;
    this.autopartType = false;
    this.tripExpenses = false;
    this.purchaseBill = false;
    this.openingBal = false;
    this.inventory = false;
    this.issueAndReturn = false;
    this.loginLogs = false;
    this.paymentReceipts = false;
    this.collectionReport = false;
    this.challanReport = false;
    this.bookingRegReport = false;
    this.ddCartageReport = false;
    this.pendingReport = false;
    this.payments = false;
    this.purchaseReturn = false;
    this.sales = false;

    if (page === '/dashboard') {
      this.dashboard = true;
    } else if (page === '/orderList' || page === '/order-list' || page === '/order-add' || page === '/order-edit' || page === '/order-detail' || page === '/order-cancel') {
      this.orderList = true;

    } else if (page === '/biltyList' || page === '/bilty-list' || page === '/bilty-add' || page === '/bilty-detail') {
      this.biltyList = true;

    } else if (page === '/crossingInwardList' || page === '/crossinginward-list' || page === '/crossinginward-add' || page === '/crossinginward-edit' || page === '/crossinginward-detail') {
      this.crossingInwardList = true;
    } else if (page === '/crossingOutwardList' || page === '/crossingoutward-list' || page === '/crossingoutward-add' || page === '/crossingoutward-edit' || page === '/crossingoutward-detail') {
      this.crossingOutwardList = true;
      
    }else if (page === '/branchList' || page === '/branch-list' || page === '/branch-add' || page === '/branch-edit' || page === '/branch-detail') {
      this.branchList = true;

    } else if (page === '/designationList' || page === '/designation-list' || page === '/designation-add' || page === '/designation-edit' || page === '/designation-detail') {
      this.designationList = true;

    } else if (page === '/userList' || page === '/user-list' || page === '/user-add' || page === '/user-edit' || page === '/user-detail') {
      this.userList = true;

    } else if (page === '/goodsList' || page === '/goods-list' || page === '/goods-add' || page === '/goods-edit' || page === '/goods-detail') {
      this.goodsList = true;

    } else if (page === '/goodsCategory' || page === '/goodscategory-list' || page === '/goodscategory-add' || page === '/goodscategory-edit') {
      this.goodsCategory = true;

    } else if (page === '/clientList' || page === '/client-list' || page === '/client-add' || page === '/client-edit' || page === '/client-detail') {
      this.clientList = true;

    } else if (page === '/vendorList' || page === '/vendor-list' || page === '/vendor-add' || page === '/vendor-edit' || page === '/vendor-detail') {
      this.vendorList = true;

    } else if (page === '/distanceweight' || page === '/ratecard-list' || page === '/ratecard-add' || page === '/ratecard-edit' || page === '/ratecard-detail') {
      this.distanceweight = true;

    } else if (page === '/quotation' || page === '/quotation-list' || page === '/quotation-add' || page === '/quotation-edit' || page === '/quotation-detail') {
      this.quotation = true;

    } else if (page === '/route-planner' || page ==='/routePlanner' || page === '/route-planner-list' || page === '/route-planner-add' || page === '/route-planner-edit' || page === '/route-planner-detail') {
      this.routePlanner = true;
    } else if (page === '/station-master' || page ==='/stationMaster' || page === '/station-master-list' || page === '/station-master-add' || page === '/station-master-edit' || page === '/station-master-detail') {
      this.stationMaster = true;
    } else if (page === '/packagingType' || page === '/packagingtype-list' || page === '/packagingtype-add' || page === '/packagingtype-edit') {
      this.packagingType = true;

    } else if (page === '/contentType' || page === '/contenttype-list' || page === '/contenttype-add' || page === '/contenttype-edit') {
      this.contentType = true;

    } else if (page === '/taxList' || page === '/tax-list' || page === '/tax-add' || page === '/tax-edit' || page === '/tax-detail') {
      this.taxList = true;

    } else if (page === '/employeeList' || page === '/employee-list' || page === '/employee-add' || page === '/employee-edit' || page === '/employee-detail') {
      this.employeeList = true;

    } else if (page === '/employeeTypeList' || page === '/employeetype-list' || page === '/employeetype-add' || page === '/employeetype-edit') {
      this.employeeTypeList = true;

    } else if (page === '/vehicleTypeList' || page === '/vehicletype-list' || page === '/vehicletype-add' || page === '/vehicletype-edit') {
      this.vehicleTypeList = true;

    } else if (page === '/vehicleManufacturerList' || page === '/vehiclemanufacturer-list' || page === '/vehiclemanufacturer-add' || page === '/vehiclemanufacturer-edit' ) {
      this.vehicleManufacturerList = true;

    } else if (page === '/vehicleList' || page === '/vehicle-list' || page === '/vehicle-add' || page === '/vehicle-edit' || page === '/vehicle-detail' || page === '/vehicle-version') {
      this.vehicleList = true;

    } else if (page === '/loadingList' || page === '/loading-list' || page === '/loading-add' || page === '/loading-edit' || page === '/loading-detail') {
      this.loadingList = true;

    } else if (page === '/challanList' || page === '/challan-list' || page === '/challan-add' || page === '/challan-edit' || page === '/challan-detail') {
      this.challanList = true;

    } else if (page === '/crossingChallanList' || page === '/crossingchallan-list' || page === '/crossingchallan-add' || page === '/crossingchallan-edit' || page === '/crossingchallan-detail') {
      this.crossingChallanList = true;

    } else if (page === '/inwardChallanList' || page === '/inwardchallan-list' || page === '/inwardchallan-add' || page === '/inwardchallan-edit' || page === '/inwardchallan-detail') {
      this.inwardChallanList = true;

    } else if (page === '/drList' || page === '/dr-list' || page === '/dr-add' || page === '/dr-edit' || page === '/dr-detail') {
      this.drList = true;

    } else if (page === '/deliverychallanList' || page === '/deliverychallan-list' || page === '/deliverychallan-add' || page === '/deliverychallan-edit' || page === '/deliverychallan-detail') {
      this.deliveryChallanList = true;

    } else if (page === '/podList' || page === '/pod-list' || page === '/pod-add' || page === '/pod-edit' || page === '/pod-detail') {
      this.podList = true;

    } else if (page === '/tbbInvoiceList' || page === '/tbb-invoice-list' || page === '/tbb-invoice-add' || page === '/tbb-invoice-edit' || page === '/tbb-invoice-detail') {
      this.tbbInvoiceList = true;

    }else if (page === '/crossingOutwardInvoiceList' || page === '/crossing-outward-invoice-list' || page === '/crossing-outward-invoice-add' || page === '/crossing-outward-invoice-edit' || page === '/crossing-outward-invoice-detail') {
      this.crossingOutwardInvoiceList = true;

    }  else if (page === '/accountingGroupList' || page === '/accountgroup-list' || page === '/accountgroup-add' || page === '/accountgroup-edit' || page === '/accountgroup-detail') {
      this.accountingGroupList = true;

    } else if (page === '/accountingSubGroupList' || page === '/accountsubgroup-list' || page === '/accountsubgroup-add' || page === '/accountsubgroup-edit' || page === '/accountsubgroup-detail') {
      this.accountingSubGroupList = true;

    } else if (page === '/accountList' || page === '/account-list' || page === '/account-add' || page === '/account-edit' || page === '/account-detail') {
      this.accountList = true;

    } else if (page === '/bankreconciliation-report' || page === '/bankreconciliation-report') {
      this.bankReconciliationReportList = true;

    } else if (page === '/generalEntryList' || page === '/generalentry-list' || page === '/generalentry-add' || page === '/generalentry-edit' || page === '/generalentry-detail') {
      this.generalEntryList = true;
      this.voucherList = true;
    } else if (page === '/paymentVoucherList' || page === '/paymentvoucher-list' || page === '/paymentvoucher-add' || page === '/paymentvoucher-edit' || page === '/paymentvoucher-detail') {
      this.paymentVoucherList = true;
      this.voucherList =true;
    } else if (page === '/receiptVoucherList' || page === '/receiptvoucher-list' || page === '/receiptvoucher-add' || page === '/receiptvoucher-edit' || page === '/receiptvoucher-detail') {
      this.receiptVoucherList = true;
      this.voucherList = true;
    } else if (page === '/bankVoucherList' || page === '/bank-voucher-list' || page === '/bank-voucher-add' || page === '/bank-voucher-edit' || page === '/bank-voucher-detail') {
      this.bankVoucherList = true;
      this.voucherList = true;
    } else if (page === '/contraEntryList' || page === '/contraentry-list' || page === '/contraentry-add' || page === '/contraentry-edit' || page === '/contraentry-detail') {
      this.contraEntryList = true;
      this.voucherList = true;
    } else if (page === '/creditNoteList' || page === '/creditnote-list' || page === '/creditnote-add' || page === '/creditnote-edit' || page === '/creditnote-detail') {
      this.creditNoteList = true;
      this.voucherList = true;
    } else if (page === '/debitNoteList' || page === '/debitnote-list' || page === '/debitnote-add' || page === '/debitnote-edit' || page === '/debitnote-detail') {
      this.debitNoteList = true;
      this.voucherList = true;
    } else if (page === '/voucherList' || page === '/voucher-list' || page === '/voucher-add') {
      this.voucherList = true;
    }
    else if (page === '/reports' || page === '/journal-report' || page === '/journal-detail-report' || page=== '/day-book-report' || page === '/ledger-report' || page === '/cashbook-report' || page === '/bankbook-report' || page === '/trialbalance-report' || page === '/profitloss-report' || page === '/balancesheet-report' || page === '/pendingdr-report' || page === '/loginlogs-report' || page === '/collection-report' || page === '/doordelivery-report' || page === '/drstock-report' || page === '/motorfreight-report' || page === '/ddcartage-report'  || page === '/bookingregister-report' ) {
      this.reports = true;
    } else if (page === '/ledger') {
      this.ledgers = true;

    } else if (page === '/loginLogs') {
      this.loginLogs = true;

    } else if (page === '/categoryList' || page === '/category-list' || page === '/category-add' || page === '/category-edit') {
      this.categoryList = true;

    } else if (page === '/autopartList' || page === '/items-list' || page === '/items-add' || page === '/items-edit' || page === '/items-detail') {
      this.autopartList = true;

    } else if (page === '/autopartType' || page === '/subcategory-list' || page === '/subcategory-add' || page === '/subcategory-edit' || page === '/subcategory-detail') {
      this.autopartType = true;

    } else if (page === '/tripExpenses') {
      this.tripExpenses = true;

    } else if (page === '/purchaseBill' || page === '/purchaseinvoice-list' || page === '/purchaseinvoice-add' || page === '/purchaseinvoice-edit' || page === '/purchaseinvoice-detail') {
      this.purchaseBill = true;

    } else if (page === '/openingBal' || page === '/openingbalance-list' || page === '/openingbalance-add' || page === '/openingbalance-edit' || page === '/openingbalance-detail') {
      this.openingBal = true;

    } else if (page === '/inventory' || page === '/inventory-list') {
      this.inventory = true;

    } else if (page === '/issueAndReturn' || page === '/issue-return') {
      this.issueAndReturn = true;

    } else if (page === '/paymentReceipts') {
      this.paymentReceipts = true;

    } else if (page === '/collectionReport') {
      this.collectionReport = true;

    } else if (page === '/challanReport') {
      this.challanReport = true;

    } else if (page === '/bookingRegReport') {
      this.bookingRegReport = true;

    } else if (page === '/pendigReport') {
      this.pendingReport = true;

    } else if (page === '/payments' || page === '/payments-list' || page === '/payments-add' || page === '/payments-edit' || page === '/payments-detail') {
      this.payments = true;

    } else if (page === '/purchaseReturn') {
      this.payments = true;

    } else if (page === '/sales'|| page === '/salesinvoice-list' || page === '/salesinvoice-add' || page === '/salesinvoice-edit' || page === '/salesinvoice-detail') {
      this.sales = true;

    } else if (page === '/contacts') {
      this.contacts = true;

    } else {

    }

  }

  navigate(page) {
    page = page.trim();
    page = page.toString();
    this.companyDetail = false;
    this.dateTime = false;
    this.workFlow = false;
    this.localName = false;
    this.documentNumber = false;
    this.voucherNumber = false;
    this.biltySettings = false;
    this.biltyTabSettings = false;
    this.biltyPrintSettings = false;
    this.biltyIndexSettings = false;
    this.settings = false;
    this.employeeSetting = false;
    this.vehicleSetting = false;
    this.vendorSetting = false;
    this.languageSetting = false;
    this.notificationSetting = false;
    this.loadingSetting = false;
    this.crossingInwardSetting = false;
    this.crossingOutwardSetting = false;
    this.challanSetting = false;
    this.paymentModeSetting = false;
    this.orderSeting = false;
    this.clientSetting = false;
    this.fileImport = false;
    this.fileExport = false;


    if (page === '/settings/company-detail') {
      this.companyDetail = true;

    } else if (page === '/settings/company-edit') {
      this.companyDetail = true;

    } else if (page === '/settings/work-flow') {
      this.workFlow = true;

    } else if (page === '/settings/date-time') {
      this.dateTime = true;

    } else if (page === '/settings/document-numbering') {
      this.documentNumber = true;

    } else if (page === '/settings/voucher-numbering') {
      this.voucherNumber = true;

    } else if (page === '/settings/local-name-settings') {
      this.localName = true;

    } else if (page === '/settings/bilty-settings') {
      this.biltySettings = true;

    } else if (page === '/settings/bilty-tab-settings') {
      this.biltyTabSettings = true;

    } else if (page === '/settings/bilty-print-settings') {
      this.biltyPrintSettings = true;

    } else if (page === '/settings/bilty-index-settings') {
      this.biltyIndexSettings = true;

    } else if (page === '/settings/employee-settings') {
      this.employeeSetting = true;

    } else if (page === '/settings/vehicle-settings') {
      this.vehicleSetting = true;

    } else if (page === '/settings/vendor-settings') {
      this.vendorSetting = true;

    } else if (page === '/settings/language-settings') {
      this.languageSetting = true;

    } else if (page === '/settings/notification-settings') {
      this.notificationSetting = true;

    }else if (page === '/settings/loading-settings') {
      this.loadingSetting = true;

    }else if (page === '/settings/crossing-inward-settings') {
      this.crossingInwardSetting = true;

    }else if (page === '/settings/crossing-outward-settings') {
      this.crossingOutwardSetting = true;

    }else if (page === '/settings/challan-settings') {
      this.challanSetting = true;

    } else if (page === '/settings/dr-settings') {
      this.drSetting = true;

    } else if (page === '/settings/payment-method-settings') {
      this.paymentModeSetting = true;

    } else if (page === '/settings/client-settings') {
      this.clientSetting = true;

    } else if (page === '/settings/order-settings') {
      this.orderSeting = true;

    } else if (page === '/settings/import') {
      this.fileImport = true;

    } else if (page === '/settings/export') {
      this.fileExport = true;

    } else if (page === '/settings') {
      this.settings = true;

    }

  }

  transified(page) {
    page = page.trim();
    page = page.toString();
    this.transifiedFileImport = false;
    this.trfdImport = false;
    this.trfdDashboard = false;
    this.trfdImportCancel = false;

    if (page === '/transified/import-list') {
      this.transifiedFileImport = true;
    } else if (page === '/transified/dashboard') {
      this.trfdDashboard = true;
    } else if (page === '/transified/trfd-import-cancel') {
      this.trfdImportCancel = true;

    }
  }
}
